<template>
  <div>
    <div>
      {{ heritage_Name }}
    </div>
    <el-button
      type="primary"
      icon="el-icon-plus"
      @click="visible = true"
      :disabled="disabled"
      >选择单位</el-button
    >
    <el-dialog v-model="visible" title="选择单位">
      <div class="search-row">
        <el-space>
          <el-select v-model="filter.isUnit" placeholder="类型" clearable>
            <el-option label="文保单位" :value="true"></el-option>
            <el-option label="文博机构" :value="false"></el-option>
          </el-select>
          <!-- <el-select v-model="filter.city" placeholder="市级" clearable>
            <el-option
              v-for="item in dicCity"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
          <el-select v-model="filter.town" placeholder="区县" clearable>
            <el-option
              v-for="item in dicTown"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
          <el-select
            v-model="filter.publictype"
            placeholder="公布类型"
            clearable
          >
            <el-option
              v-for="item in dicUnitProperty"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select> -->
          <el-input
            placeholder="名称"
            v-model="filter.keyword"
            style="width: 500px"
          >
            <template #prepend> 关键字 </template>
          </el-input>
          <el-button
            type="primary"
            style="margin-left: 5px"
            @click="
              () => {
                filter.pageNum = 1;
                query();
              }
            "
            >查询</el-button
          >
        </el-space>
      </div>
      <el-table :data="result.list" v-loading="isLoading">
        <el-table-column
          label="单位名称"
          prop="heritage_Name"
        ></el-table-column>
        <el-table-column
          label="级别"
          prop="heritageLevel_Name"
        ></el-table-column>
        <el-table-column
          label="批次"
          prop="heritageBatch_Name"
        ></el-table-column>
        <el-table-column label="所在市县">
          <template #default="{ row: item }">
            {{ item.city_Name }}
            {{ item.county_Name }}
          </template>
        </el-table-column>
        <el-table-column
          label="公布类型"
          prop="heritageClass_Name"
        ></el-table-column>
        <el-table-column label="操作">
          <template #default="{ row: item }">
            <el-button
              type="primary"
              icon="el-icon-check"
              @click="selected(item)"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="padding-left: 5px"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        v-model:page-size="filter.pageSize"
        :total="result.count"
        layout="sizes,total, prev, pager, next"
        v-model:currentPage="filter.pageNum"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, toRefs, toRef, watch } from "vue";
import { useQueryHeritages } from "@/network/heritageUnit.ts";
export default defineComponent({
  emits: ["update:modelValue", "change"],
  props: {
    setting: { type: Object },
    modelValue: { type: Number },
  },
  setup(props, { emit }) {
    const { visible, disabled, heritage_Name } = toRefs(props.setting as any);

    const filter = reactive({
      isUnit: null,
      city: null,
      town: null,
      publictype: null,
      keyword: null,
      pageNum: 1,
      pageSize: 10,
    });
    const [isLoading, result, query] = useQueryHeritages(filter);
    const selected = (item) => {
      visible.value = false;
      heritage_Name.value = item.heritage_Name;
      emit("update:modelValue", item.id);
      emit("change", item);
    };
    // watch(
    //   () => props.modelValue,
    //   () => {}
    // );
    return {
      filter,
      isLoading,
      heritage_Name,
      result,
      query,
      selected,
      visible,
      disabled,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
    };
  },
});
</script>

<style scoped>
</style>