
import { defineComponent, reactive, ref, toRefs, toRef, watch } from "vue";
import { useQueryHeritages } from "@/network/heritageUnit.ts";
export default defineComponent({
  emits: ["update:modelValue", "change"],
  props: {
    setting: { type: Object },
    modelValue: { type: Number },
  },
  setup(props, { emit }) {
    const { visible, disabled, heritage_Name } = toRefs(props.setting as any);

    const filter = reactive({
      isUnit: null,
      city: null,
      town: null,
      publictype: null,
      keyword: null,
      pageNum: 1,
      pageSize: 10,
    });
    const [isLoading, result, query] = useQueryHeritages(filter);
    const selected = (item) => {
      visible.value = false;
      heritage_Name.value = item.heritage_Name;
      emit("update:modelValue", item.id);
      emit("change", item);
    };
    // watch(
    //   () => props.modelValue,
    //   () => {}
    // );
    return {
      filter,
      isLoading,
      heritage_Name,
      result,
      query,
      selected,
      visible,
      disabled,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
    };
  },
});
